export const htmlToPlainText = (html: string): string => {
  // Clean up the text:
  // 1. Replace multiple spaces with single space
  // 2. Replace &nbsp; with space
  // 3. Remove HTML tags
  // 4. Remove leading/trailing whitespace
  return html
    .replace(/<[^>]*>/g, "") // Remove HTML tags
    .replace(/\s+/g, " ")
    .replace(/&nbsp;/g, " ")
    .replace(/\n\s*\n/g, "\n")
    .trim();
};
