'use client'

import { sanitizeHTML } from "@/lib/utils";
import { useState } from "react";
import { useForm } from "react-hook-form";

interface BookAConsultationNextData {
  heading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
}

type FormData = {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  procedure: string;
  timeframe: string;
  questions: string;
  source: string;
}; 

export const fields = [
  "heading.text",
  "heading.title",
  "text.text",
] as const;

interface BookAConsultationNextProps {
  data?: BookAConsultationNextData;
}

export default function BookAConsultationNext({ data }: BookAConsultationNextProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [formSuccess, setFormSuccess] = useState('');

  const { register, handleSubmit, reset, formState: { errors } } = useForm<FormData>({
    mode: 'onBlur'
  });

  const onSubmit = async (formData: FormData) => {
    setIsLoading(true);
    setFormError('');
    setFormSuccess('');

    try {
      const response = await fetch('/api/book-consultation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (!response.ok) throw new Error();

      setFormSuccess('Thank you for your consultation request! We will contact you soon.');
      reset();
    } catch (error) {
      setFormError('Failed to submit form. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section id="book-a-consultation" className="bg-white py-14">
      <div className="max-w-[1080px] mx-auto px-4">
        <h2 
          className="text-4xl md:text-5xl font-display text-center text-secondary-dark mb-8"
          dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.title || data?.heading?.text || '')}
        />
        <div
          className="mb-8 prose prose-ul:space-y-2 prose-ul:text-secondary-dark prose-ul:list-disc prose-li:text-sm prose-li:font-aileron max-w-none"
          dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')}
        />

        {formError && (
          <div className="p-3 mb-6 text-sm text-red-500 bg-red-50 rounded-md text-center">
            {formError}
          </div>
        )}
        {formSuccess && (
          <div className="p-3 mb-6 text-sm text-green-500 bg-green-50 rounded-md text-center">
            {formSuccess}
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 max-w-[710px] mx-auto">
          <div>
            <label
              htmlFor="firstname"
              className="block text-sm font-semibold text-secondary-dark mb-2"
            >
              First Name*
            </label>
            <input
              {...register('firstname', {
                required: 'First name is required',
                pattern: { value: /^[a-zA-Z\s]*$/, message: 'Only letters allowed' }
              })}
              type="text"
              id="firstname"
              name="firstname"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
              placeholder="Type your first name"
            />
            {errors.firstname && (
              <p className="mt-1 text-sm text-red-500">{errors.firstname.message}</p>
            )}
          </div>
          <div>
            <label
              htmlFor="lastname"
              className="block text-sm font-semibold text-secondary-dark mb-2"
            >
              Last Name*
            </label>
            <input
              {...register('lastname', {
                required: 'Last name is required',
                pattern: { value: /^[a-zA-Z\s]*$/, message: 'Only letters allowed' }
              })}
              type="text"
              id="lastname"
              name="lastname"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
              placeholder="Type your last name"
            />
            {errors.lastname && (
              <p className="mt-1 text-sm text-red-500">{errors.lastname.message}</p>
            )}
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-secondary-dark mb-2"
            >
              Email*
            </label>
            <input
              {...register('email', {
                required: 'Email is required',
                pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email address' }
              })}
              type="email"
              id="email"
              name="email"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
              placeholder="Type your email"
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-500">{errors.email.message}</p>
            )}
          </div>
          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-semibold text-secondary-dark mb-2"
            >
              Phone*
            </label>
            <input
              {...register('phone', {
                required: 'Phone number is required',
                pattern: { value: /^[0-9+\-\s()]*$/, message: 'Invalid phone number' }
              })}
              type="tel"
              id="phone"
              name="phone"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
              placeholder="Type your phone number"
            />
            {errors.phone && (
              <p className="mt-1 text-sm text-red-500">{errors.phone.message}</p>
            )}
          </div>
          <div>
            <label
              htmlFor="procedure"
              className="block text-sm font-semibold text-secondary-dark mb-2"
            >
              Procedure(s) of Interest*
            </label>
            <select
              {...register('procedure', { required: 'Please select a procedure' })}
              id="procedure"
              name="procedure"
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
            >
              <option value="">Select a procedure</option>
              <option value="Facelift | Neck lift | Sagging Jaw Line">
                Facelift | Neck lift | Sagging Jaw Line
              </option>
              <option value="Blepharoplasty| Eyelid surgery| Eye Bags">
                Blepharoplasty| Eyelid surgery| Eye Bags
              </option>
              <option value="Rhinoplasty | Nose Reshaping | Nose job">
                Rhinoplasty | Nose Reshaping | Nose job
              </option>
              <option value="Chin Implants">Chin Implants</option>
              <option value="Others | I am not sure">
                Others | I am not sure
              </option>
            </select>
            {errors.procedure && (
              <p className="mt-1 text-sm text-red-500">{errors.procedure.message}</p>
            )}
          </div>
          <div>
            <label
              htmlFor="timeframe"
              className="block text-sm font-semibold text-secondary-dark mb-2"
            >
              When are you thinking of having surgery?
            </label>
            <select
              {...register('timeframe')}
              id="timeframe"
              name="timeframe"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
            >
              <option value="">Select a timeframe</option>
              <option value="0-3 months">0-3 months</option>
              <option value="3-6 months">3-6 months</option>
              <option value="6-9 months">6-9 months</option>
              <option value="9 months or later">9 months or later</option>
            </select>
          </div>
          <div>
            <label
              htmlFor="questions"
              className="block text-sm font-semibold text-secondary-dark mb-2"
            >
              Questions and Comments
            </label>
            <textarea
              {...register('questions')}
              id="questions"
              name="questions"
              rows={4}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
            ></textarea>
          </div>
          <div>
            <label
              htmlFor="source"
              className="block text-sm font-semibold text-secondary-dark mb-2"
            >
              Where did you hear about Dr Julian De Silva?*
            </label>
            <select
              {...register('source', { required: 'Please select a source' })}
              id="source"
              name="source"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
            >
              <option value="">Select a source</option>
              <option value="Website">Website</option>
              <option value="Friends & Family">Friends & Family</option>
              <option value="Google">Google</option>
              <option value="Instagram">Instagram</option>
              <option value="Facebook">Facebook</option>
              <option value="YouTube">YouTube</option>
              <option value="Google Advert">Google Advert</option>
              <option value="Article">Article</option>
              <option value="Referral">Referral</option>
            </select>
            {errors.source && (
              <p className="mt-1 text-sm text-red-500">{errors.source.message}</p>
            )}
          </div>

        {formError && (
          <div className="p-3 mb-6 text-sm text-red-500 bg-red-50 rounded-md text-center">
            {formError}
          </div>
        )}
        {formSuccess && (
          <div className="p-3 mb-6 text-sm text-green-500 bg-green-50 rounded-md text-center">
            {formSuccess}
          </div>
        )}

          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-secondary hover:bg-secondary-dark text-white font-semibold py-3 px-6 rounded-md transition duration-300 disabled:opacity-50"
          >
            {isLoading ? 'Sending...' : 'Send Enquiry'}
          </button>
        </form>
      </div>
    </section>
  );
}
