import { sanitizeHTML } from "@/lib/utils";
interface CandidateDeepPlaneData {
  heading?: {
    text?: string;
    title?: string;
  };
  text?: {
    text?: string;
  };
}

export const fields = [
  "heading.text",
  "heading.title",
  "text.text",
] as const;

export default function CandidateDeepPlane({ data }: { data?: CandidateDeepPlaneData }) {
  return (
		<section id='candidate-deep-plane-link1' className='bg-[#d9d9d9]'>
			<div className='container py-24 md:py-28'>
				<div className='grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-18'>
					{/* Left Column */}
					<div className='flex flex-col gap-10'>
						<h2
							className='text-4xl lg:text-[50px] font-light font-freightBig leading-[1.1]'
							dangerouslySetInnerHTML={sanitizeHTML(
								data?.heading?.title || data?.heading?.text || ''
							)}
						/>
					</div>
					{/* Right Column */}
					<div className='flex flex-col gap-5'>
						<div
							className='robx prose prose-li:my-2 prose-li:text-black prose-ul:pl-5 prose-ul:list-disc prose-ul:text-black prose-li:marker:text-black text-base font-aileron text-black'
							dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')}
						/>
					</div>
				</div>
			</div>
		</section>
	)
}
