import Button from "@/components/ui/button";
import Video from "@/components/shared/VideoComp/Video";
import Link from "@/components/ui/Link";
import SplitImagesWithTitle, {
  SplitItems,
} from "@/components/ui/SplitImagesWithTitle";

interface CaseStudySectionProps {
  data?: any;
}

const twelveHoursItems: SplitItems = [
  {
    title: "12-hours after treatment",
    imageSrc: "/images/content_split_left_image_white_img_3.jpg",
  },
  {
    title: "12-hours after treatment",
    imageSrc: "/images/content_split_left_image_white_img_3.jpg",
  },
];

const oneWeekItems: SplitItems = [
  {
    title: "1-week after facelift & neck lift surgery",
    imageSrc: "/images/content_split_left_image_white_img_3.jpg",
  },
  {
    title: "1-week after facelift & neck lift surgery",
    imageSrc: "/images/content_split_left_image_white_img_3.jpg",
  },
];

export default function CaseStudySection({ data }: CaseStudySectionProps) {
  return (
    <section id="case-study-section" className="py-20 px-4 md:px-8 lg:px-0">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="space-y-6">
            <h4 className="text-sm uppercase tracking-wider font-bold">
              FACIAL SURGERY IN HARLEY STREET
            </h4>
            <h2 className="font-freightBig text-3xl md:text-4xl text-secondary-dark">
              Facelift & Neck Lift Case Study
            </h2>
            <div className="relative aspect-video w-full">
              <Video imgSrc="/images/content_split_left_image_white_img_3.jpg">
                <iframe
                  src="https://www.youtube.com/embed/d-wKHI5moiY"
                  title="Facelift & Neck Lift Review & Testimonial"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  className="absolute inset-0 w-full h-full"
                  allowFullScreen
                  loading={"lazy"}
                />
              </Video>
            </div>
          </div>
          <div className="lg:pl-8 space-y-6">
            <div className="prose max-w-full text-[#000] font-aileron">
              <p className="font-bold">
                This woman had disliked the appearance and general sagginess of
                her neck for more than 10 years. She was very anxious about
                undergoing treatment and although had been offered non-surgical
                treatments, no treatments had made any real difference to her
                neck. She presented for a consultation with Dr. De Silva, to
                discuss neck lifting options, she was not keen on undergoing
                invasive surgery with a prolonged recovery time.
              </p>
              <p>
                On assessment, the skin along her jaw had begun sagging
                considerably with time, with loose skin and some accumulation of
                fat, softening her jaw line and leaving her jaw looking uneven.
                Dr. De Silva assessed her neck and discussed with her the best
                possible improvement with the least downtime and advised a
                combined face and neck lift procedure with fat transfer and the
                use of bio-technology to enhance her recovery from surgery. With
                natural neck elasticity there are more treatment options
                available to improve a sagging neck.
              </p>
              <p>
                Only 12-hours after treatment, the photos show a marked
                improvement in her jawline and neck associated with a youthful
                appearance. There is some bruising of her neck, however Dr. De
                Silva uses advanced biotechnology to enhance recovery after
                surgery, the ecchymosis is largely resolved by 1-week after
                surgery. Dr. De Silva completed the surgery with twilight
                sedation, enabling the patient to avoid the side effects and
                long recovery associated with general anaesthesia. The patient
                was able to leave the surgery less than 1-hour after the surgery
                was completed.
              </p>
            </div>
          </div>
        </div>
        <div className="max-w-[1080px] mx-auto mt-12">
          <SplitImagesWithTitle items={twelveHoursItems} />
          <div className="mt-12">
            <p className="font-aileron">
              The next set of photos show her appearance at 1-week after
              surgery. Dr. De Silva uses fast recovery techniques to enhance
              recovery after face & neck lift surgery and a dramatic improvement
              in her neck can be seen. Dr. De Silva customises each facelift and
              neck lift procedure for each individual patient, to improve the
              neck to this level requires advanced surgery techniques that focus
              on improving all the underlying structures in the neck. Dr. De
              Silva's focus for each patient in terms of priority are firstly
              Safety, secondly the best possible result and thirdly
            </p>
          </div>
          <SplitImagesWithTitle items={oneWeekItems} className="mt-12" />
        </div>
        <div className="mt-12 flex justify-center">
          <Button variant="outline" asChild>
            <Link href="/book-a-consultation">Book a consultation</Link>
          </Button>
        </div>
      </div>
    </section>
  );
}
